import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "@hotwired/turbo-rails";
// import "animate.css/animate.css";
// import "confetti-js";

// Javascript
import Rails from "@rails/ujs";

import "../controllers";
import "../stylesheets/application";

Rails.start()
ActiveStorage.start()


$(document).ready(function() {
  $("[data-form-prepend]").click(function(e) {
    var obj = $($(this).attr("data-form-prepend"));
    obj.find("input, select, textarea").each(function() {
      $(this).attr("name", function() {
        return $(this)
          .attr("name")
          .replace("new_record", new Date().getTime());
      });
    });
    $(this).prev().find('tbody tr').last().after(obj);
    return false;
  });

  $("[data-form-prepend].non-table-link").click(function(e) {
    var obj = $($(this).attr("data-form-prepend"));
    obj.find("input, select, textarea").each(function() {
      $(this).attr("name", function() {
        return $(this)
          .attr("name")
          .replace("new_record", new Date().getTime());
      });
    });
    obj.insertBefore(this);
    return false;
  });  

  $('body').on('click', 'a.delete-table-row', function(e) {
    e.preventDefault();
    $(this).closest("tr").find('[type=checkbox]').prop('checked', true);
    $(this).closest("tr").hide();
    return false;
  });  

  $('.child-custody-selector').change(function(){
    var optionValue = $(this).val();
    var attributeId = $(this).data('attribute-id');
    if(optionValue){
      $('#family_children_attributes_'+attributeId+'_education_decision_user_id').css('visibility', 'hidden');
      $('#family_children_attributes_'+attributeId+'_medical_decision_user_id').css('visibility', 'hidden');
    } else{
      $('#family_children_attributes_'+attributeId+'_education_decision_user_id').css('visibility', 'visible');
      $('#family_children_attributes_'+attributeId+'_medical_decision_user_id').css('visibility', 'visible');
    }
    
  });

})
