import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["yes_button", "no_button", "boolean_field", "radio_button", "radio_field", "border", "button", "field"]

  yes_select() {
    this.yes_buttonTarget.classList.add("ring-2")
    this.yes_buttonTarget.classList.add("ring-current")

    this.no_buttonTarget.classList.remove("ring-2")
    this.no_buttonTarget.classList.remove("ring-current")

    this.boolean_fieldTarget.value = "1"
  }

  no_select() {
    this.no_buttonTarget.classList.add("ring-2")
    this.no_buttonTarget.classList.add("ring-current")

    this.yes_buttonTarget.classList.remove("ring-2")
    this.yes_buttonTarget.classList.remove("ring-current")

    this.boolean_fieldTarget.value = "0"
  }

  radio_select() {
    this.unselectRadioButtons(this.radio_buttonTargets)

    event.currentTarget.classList.add("ring-2")
    event.currentTarget.classList.add("ring-current")

    this.radio_fieldTarget.value = event.currentTarget.dataset.value
  }

  unselectRadioButtons(radio_buttonTargets) { 
    radio_buttonTargets.forEach(radio_buttonTarget => {
      radio_buttonTarget.classList.remove("ring-2")
      radio_buttonTarget.classList.remove("ring-current")
    })
  }

  toggle() {
    // Enable Hidden Field
    const hidden_field_value = this.fieldTarget.value    

    if (hidden_field_value == "1") {
      this.fieldTarget.value = "0"
    } else {
      this.fieldTarget.value = "1"
    }

    
    // Toggle Button
    this.buttonTarget.classList.toggle("bg-gray-200")
    this.buttonTarget.classList.toggle("bg-purple")
    this.buttonTarget.querySelector("span").classList.toggle("translate-x-0")
    this.buttonTarget.querySelector("span").classList.toggle("translate-x-5")
  }
}
